@import 'src/sass/main';

.main-nav,
.related-recipes,
.newsletter,
.btn,
.btn-secondary,
footer{
  display: none;
}
.product-teaser{
  width: 300px;
}

.preparation{
  margin-top: 100px;
}


